import { useRef, useEffect } from 'react';
import supabase from './supabase';
import { formatDateToYYYYMMDD } from './data-factory';

// Make an API request to `/api/{path}`
export async function apiRequest(path, method = 'GET', data) {
  const {
    data: { session },
  } = await supabase.auth.getSession();
  const accessToken = session ? session.access_token : undefined;

  return fetch(`/api/${path}`, {
    method: method,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
    body: data ? JSON.stringify(data) : undefined,
  })
    .then(response => response.json())
    .then(response => {
      if (response.status === 'error') {
        // Automatically signout user if accessToken is no longer valid
        if (response.code === 'auth/invalid-user-token') {
          supabase.auth.signOut();
        }

        throw new CustomError(response.code, response.message);
      } else {
        return response.data;
      }
    });
}

// Make an API request to any external URL
export function apiRequestExternal(url, method = 'GET', data) {
  return fetch(url, {
    method: method,
    headers: {
      accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: data ? JSON.stringify(data) : undefined,
  }).then(response => response.json());
}

// Create an Error with custom message and code
export function CustomError(code, message) {
  const error = new Error(message);
  error.code = code;
  return error;
}

// Hook that returns previous value of state
export function usePrevious(state) {
  const ref = useRef();
  useEffect(() => {
    ref.current = state;
  });
  return ref.current;
}

// Format search string to be used in textSearch supabase query.
// In Postgres, the tsquery type does not allow unescaped whitespace
// because it treats whitespace as a separator between lexemes (words).
export function formatSearchString(searchString) {
  return searchString.includes(' ') ? searchString.split(' ').join(' & ') : searchString;
}

/**
 * a function to get the user's profile photo from the database
 * using the user's id or the user's metadata. Has three fallbacks:
 * 1. If the user has uploaded a profile photo, get it from the uploads storage.
 * 2. If the user has not uploaded a profile photo, get the user's picture from the user's metadata.
 * @param {*} id
 * @param {*} userMetadata
 * @returns
 */
export async function getProfilePhoto(id) {
  if (!id) {
    return;
  }

  const timestamp = new Date().getTime();
  const { data, error } = await supabase.storage
    .from('uploads')
    .download(`${id}/profilephoto/profilephoto.jpg`);

  if (error) {
    console.error(error, 'Then fallback to other sources');
  }

  if (data && !error) {
    const url = `https://api.showrunner.co/storage/v1/object/public/uploads/${id}/profilephoto/profilephoto.jpg?v=${timestamp}`;
    return url;
  } else {
    const user = await getUser(id);
    return user.picture;
  }
}

/**
 * a function to get the cover photo for a list
 * @param {*} id
 * @returns
 */
export async function getFeaturedListCoverPhoto(id) {
  if (!id) {
    return;
  }

  const { data, error } = await supabase.storage
    .from('featured-list-covers')
    .download(`${id}/coverphoto/coverphoto.jpg`);

  if (error) {
    console.error(error, 'Could not retrieve cover photo');
  } else if (data) {
    const url = `https://api.showrunner.co/storage/v1/object/public/featured-list-covers/${id}/coverphoto/coverphoto.jpg`;
    return url;
  }
}

/**
 * Upload a profile photo to the user's uploads folder storage.
 * If a file already exists, update it, otherwise upload a new one.
 * @param {*} file
 * @param {*} userId
 * @returns
 */
export async function uploadProfilePhoto(file, userId) {
  const { data, error } = await supabase.storage.from('uploads').list(`${userId}/profilephoto`);

  if (error) {
    throw error;
  }

  // If there is already a file, update it, otherwise upload a new one
  // This is to avoid creating multiple files with the same name.
  if (data.length) {
    const new_file = new File([file], `profilephoto.jpg`, { type: file.type });
    const { data, error } = await supabase.storage
      .from('uploads')
      .update(`${userId}/profilephoto/profilephoto.jpg`, new_file, {
        cacheControl: '3600',
        upsert: true,
      });

    if (error) {
      throw error;
    } else {
      return data;
    }
  } else {
    const newFileName = 'profilephoto.jpg';
    const { uploadData, uploadError } = await supabase.storage
      .from('uploads')
      .upload(`${userId}/profilephoto/${newFileName}`, file);
    if (uploadError) {
      throw uploadError;
    } else {
      return uploadData;
    }
  }
}

/**
 * a function to get shows from the database based on sub-filters.
 * Multi-select filters are passed as sets.
 * @param {*} param0
 * @returns
 */
export async function getShows({
  open,
  sortedByRelevance,
  selectedMediums,
  selectedLocation,
  selectedSubject,
  selectedStyles,
  textSearch,
  fairName,
}) {
  const today = formatDateToYYYYMMDD(new Date());
  const twoWeeksFromNow = formatDateToYYYYMMDD(new Date(Date.now() + 14 * 24 * 60 * 60 * 1000));

  let query = supabase
    .from('shows')
    .select('*, venues!inner(neighborhood, venue_name, latitude, longitude, address1)')
    .eq('venues.city', 'New York');

  // if there is a fair name, search for shows with that fair name
  // if there is a text search, search for shows with that text search
  // if there is no text search, default to shows that haven't ended yet
  if (fairName || textSearch) {
    if (fairName) {
      query.textSearch('show_type', formatSearchString(fairName));
    }
    if (textSearch) {
      query
        .textSearch('search_terms', formatSearchString(textSearch))
        .order('opening_sort', { ascending: false });
    }
  } else {
    query.eq('show_type', 'gallery');
    query.gte('end_date', today);
  }

  if (selectedLocation.size > 0) {
    query.in('venues.neighborhood', [...selectedLocation]);
  }

  if (selectedMediums.size > 0) {
    query.overlaps('show_medium', [...selectedMediums]);
  }

  if (selectedStyles.size > 0) {
    query.overlaps('show_style', [...selectedStyles]);
  }

  if (selectedSubject.size > 0) {
    query.overlaps('show_subject_matter', [...selectedSubject]);
  }

  let timeConditions = [];
  if (open.has('On View')) {
    query = query.lt('start_date', today).gte('end_date', today);
    query.order('show_relevance', { ascending: false });
  }
  if (open.has('Opening Today')) {
    timeConditions.push(`start_date.eq.${today}`);
    timeConditions.push(`opening_date.eq.${today}`);
    const combinedtimeConditions = timeConditions.join(',');
    query = query.or(combinedtimeConditions);
    query.order('show_relevance', { ascending: false });
  }
  if (open.has('Opening Soon')) {
    timeConditions.push(`start_date.gte.${today}`);
    const combinedtimeConditions = timeConditions.join(',');
    query = query.or(combinedtimeConditions);
    query.order('opening_sort', { ascending: true });
  }
  if (open.has('Closing Soon')) {
    query.gte('end_date', today);
    timeConditions.push(`end_date.lte.${twoWeeksFromNow}`);
    const combinedtimeConditions = timeConditions.join(',');
    query = query.or(combinedtimeConditions);
    query.order('closing_sort', { ascending: true });
  }

  if (sortedByRelevance) {
    query.order('show_relevance', { ascending: false });
  }

  const { data, error } = await query;
  //console.debug(data);

  if (error) {
    throw error;
  }

  return data;
}

// a function to get a single show by its id
export async function getShowById(showId) {
  const { data, error } = await supabase
    .from('shows')
    .select(
      '*, venues!inner(neighborhood, venue_name, city, address1, phone, latitude, longitude, image_urls )'
    )
    .eq('show_id', showId);

  if (error) {
    throw error;
  }

  return data;
}

/**
 * From a list of show IDs, get the shows from the database.
 * @param {*} showIds
 * @returns
 */
export async function getShowsByIDs(showIds) {
  const { data, error } = await supabase
    .from('shows')
    .select(
      '*, venues!inner(neighborhood, venue_name, city, address1, phone, latitude, longitude, image_urls )'
    )
    .in('show_id', showIds);

  if (error) {
    throw error;
  }

  return data;
}

/**
 * A function to get saved lists by the owner's ID.
 * @param {*} owner
 * @returns
 */
export async function getListsByOwner(owner) {
  const { data, error } = await supabase.from('saved_lists').select('*').eq('owner', owner);
  if (error) {
    throw error;
  }
  const listsWithFirstShow = await Promise.all(
    data.map(async list => {
      if (list.shows.length > 0) {
        const res = await supabase.from('shows').select('*').eq('show_id', list.shows[0]);

        if (res.error) {
          throw res.error;
        }

        return {
          ...list,
          first_show_data: res.data[0],
        };
      }

      return list;
    })
  );

  return listsWithFirstShow;
}

/**
 * a function to get saved lists by the username
 * @param {*} username
 * @returns
 */
export async function getListsByUsername(username) {
  const { data, error } = await supabase
    .from('users')
    .select('*')
    .eq('username', username)
    .single();
  if (error) {
    throw error;
  }

  try {
    const userLists = await getListsByOwner(data.id);
    return { userData: data, userLists };
  } catch (error) {
    throw error;
  }
}

/**
 * Get user obejct by uid.
 * @param {*} uid
 * @returns
 */
export async function getUser(uid) {
  const { data, error } = await supabase
    .from('users')
    .select('picture, name, username, about, created_at')
    .eq('id', uid)
    .single();

  if (error) {
    throw error;
  }

  return data;
}

// a function to get a single list by its id.
export async function getListById(listId) {
  const { data, error } = await supabase.from('saved_lists').select('*').eq('id', listId).single();
  if (error) {
    throw error;
  }

  if (data.shows.length > 0) {
    const res = await supabase
      .from('shows')
      .select('*, venues!inner(neighborhood, venue_name, longitude, latitude)')
      .in('show_id', data.shows);

    if (res.error) {
      throw res.error;
    }

    return {
      ...data,
      shows_data: res.data,
    };
  }

  // if there are no shows in the list,
  // return the list with an empty shows_data array
  return { ...data, shows_data: [] };
}

export async function getUsernameByList(listOwnerId) {
  const { data, error } = await supabase
    .from('users')
    .select('username')
    .eq('id', listOwnerId)
    .single();

  if (error) {
    throw error;
  }
  return data?.username;
}

export async function searchHeadTypeSeacrh(searchString) {
  let query = supabase
    .from('shows')
    .select('*, venues!inner(neighborhood, venue_name, latitude, longitude, address1)')
    .textSearch('search_terms', formatSearchString(searchString))
    .order('opening_sort', { ascending: false });

  const { data, error } = await query;

  if (error) {
    throw error;
  }

  return data;
}

// a function to get list objects from the lists tables that match owner id
export async function getFeaturedLists() {
  const { data, error } = await supabase.from('saved_lists').select('*').eq('is_featured', true);

  if (error) {
    throw error;
  }

  const listsWithCoverPhotos = await Promise.all(
    data.map(async list => {
      if (list.shows.length > 0) {
        const coverPhoto = await getFeaturedListCoverPhoto(list.id);

        if (coverPhoto) {
          return {
            ...list,
            cover_photo: coverPhoto,
          };
        }

        const firstShowPhoto = await supabase
          .from('shows')
          .select('*')
          .eq('show_id', list.shows[0]);

        if (firstShowPhoto.error) {
          throw firstShowPhoto.error;
        }

        return {
          ...list,
          cover_photo: firstShowPhoto.data[0]?.image_urls[0],
        };
      }

      return list;
    })
  );

  const listsWithOwnerData = await Promise.all(
    listsWithCoverPhotos.map(async list => {
      if (list.shows.length > 0) {
        const res = await supabase.from('users').select('*').eq('id', list.owner).single();
        if (res.error) {
          throw res.error;
        }

        return {
          ...list,
          username: res.data.username,
          userPhoto: await getProfilePhoto(res.data.id),
        };
      }

      return list;
    })
  );

  return listsWithOwnerData;
}
